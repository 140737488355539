import React from 'react';
import '../InfoPrincipal/InfoPrincipal.css';

function InfoPrincipal({ data, listas, listasDesc2, listasDesc3 }) {
    return (
        <section className="container-fluid bg-white">
            <div className="row">
                <div className="col-12 col-md-6">
                    {data?.map((img, index) => (
                        <img key={index} className="Fregadora" src={img.imagen} alt="" />
                    ))}
                </div>
                <div className="col-12 col-md-6 d-flex align-content-center flex-wrap mb-5 mb-md-0">
                    <div className="w-100 py-3">
                        {data?.map((codigo, index) => (
                            <p key={index} className="textcolor text-start mb-2 RobotoBold">
                                <strong>{codigo.codigo}</strong>
                            </p>
                        ))}
                        {data?.map((titulo, index) => (
                            <div key={index}>
                                <h1 className="text-start RobotoBold tituloh1 mb-2">
                                    <strong>{titulo.titulo}</strong>
                                </h1>
                            </div>
                        ))}
                        {data?.map((desc, index) => (
                            <div key={index} className="textcolor text-start mb-2 RobotoLigth">
                                {desc.descripcion.split('\n').map((linea, lineaIndex) => (
                                    <p key={lineaIndex} className="textcolor text-start mb-2 RobotoLigth">{linea}</p>
                                ))}
                                <ul>
                                    {listas.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        {data?.map((descDos, index) => (
                            <p key={index} className="textcolor text-start mb-2 RobotoLigth">
                                <strong>{descDos.descripcionDos}</strong>
                            </p>
                        ))}
                        {data?.map((descTres, index) => (
                            <div key={index} className="textcolor text-start mb-2 RobotoLigth">
                                {descTres.descripcionTres}
                                <ul>
                                    {listasDesc2.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        {data?.map((descCuatro, index) => (
                            <p key={index} className="textcolor text-start mb-2 RobotoLigth">
                                {descCuatro.descripcionCuatro}
                            </p>
                        ))}
                        {data?.map((descCinco, index) => (
                            <div key={index} className="textcolor text-start mb-2 RobotoLigth">
                                {descCinco.descripcionCinco}
                                <ul>
                                    {listasDesc3.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoPrincipal;