import '../Home/main.css'

const main = () => {
    

    return(
        <section className='fondo'>
            <div>
                <img className='w-100' src="https://nilfiskchile.cl/assets/img/menu/fondo-principal-2.jpg" alt="banner" />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-lg-6 position-relative contenidoTexto pt-3 pb-5 pb-md-3'>
                        <h1 className='RobotoBold colorTitulo'>CONOCE <strong>TU EQUIPO</strong></h1>
                        <p className='RobotoBold subTitulo'><strong>Solo tienes que tomar tu celular y leer el QR de la maquina y... listo!</strong></p>
                        <p className='RobotoLigth colorTitulo mb-4'>Inmediatamente accederas a toda la informcaión que necesitas, desde como hacer funcionar el equipo, cómo realizar su mantenimiento, soluciones de problemas básicos, videos de ayuda, información y mucho más.</p>
                        <div className='iconoMaquina'>
                            <img src="https://nilfiskchile.cl/assets/img/menu/icono-maquina.jpg" alt="icono" />
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                            <div className='position-relative contenidoPersonas'>
                                <img className='personas' src="https://nilfiskchile.cl/assets/img/menu/personas.png" alt="personas" />
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default main;