import NavBar from '../components/NavBar/NavBarHome'
import RedesSociales from '../Componentes/RedesSociales/RedesSociales';
import Footer from '../Componentes/Footer/Footer'
import NavHomeBar from '../Componentes/Home/NavHomeBar'
import Main from '../Componentes/Home/main'


const Inicio = () => {


    return (

        <div>
            <NavBar/>
            <NavHomeBar/>
            <Main/>
            <RedesSociales />
            <Footer />
        </div>

    );
};

export default Inicio;